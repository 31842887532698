<template>
  <zmAppPage :show-footer="true" :show-header="true" :showBack="false" :showHeaderLeft="true" :showLeft="true" class="selectBook" title="问卷调查">
    <div class="questionnaire-container cf">
      <div class="questionnaire-title">{{data.name}}</div>
      <div :key="i" class="questionnaire-item" v-for="(item, i) in list">
        <div class="questionnaire-item__title">{{item.problemName}}</div>
        <van-checkbox-group v-model="item.value">
          <van-checkbox
            :key="j"
            :name="o.questionnaireProblemOptionId"
            class="questionnaire-item__option"
            v-for="(o, j) in item.questionnaireProblemOptionDTOList || []"
          >{{o.optionName}}</van-checkbox>
        </van-checkbox-group>
      </div>
    </div>
    <div class="page-footer" slot="footer">
      <van-button @click="onClickSubmit" class="page-footer__btn" type="info">提交</van-button>
    </div>
  </zmAppPage>
</template>

<script>
import { Toast } from 'vant';
import { getUuid } from '@/utils';
export default {
  name: 'Questionnaire',
  filters: {},
  data() {
    return {
      data: {}
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    list() {
      return this.data && this.data.questionnaireProblemDTOList ? this.data.questionnaireProblemDTOList : [];
    }
  },
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    afterFetch(res) {
      this.currentRank = res.currentRank;
    },
    getData() {
      if (this.id === undefined) {
        return this.$msg.showMsg('无效的问卷调查信息');
      }
      this.$apis.getQuestionnaireDetail(this.id).then((res) => {
        (res.questionnaireProblemDTOList || []).forEach((o) => {
          o.value = []; // 问卷的值
        });
        this.data = res;
      });
    },
    onClickSubmit() {
      const uuid = getUuid();
      const data = [];
      const unselected = [];
      this.list.forEach((o) => {
        data.push({
          code: uuid,
          questionnaireProblemId: o.questionnaireProblemId,
          questionnaireProblemOptionId: o.value.join(',')
        });

        if (o.value === undefined) unselected.push(o);
      });
      if (unselected.length) {
        this.$msg.showMsg('还有调查未完成, 请检查');
        return;
      }
      console.log('data', data);
      this.$apis.questionnaireVote(data).then(() => {
        Toast({ message: '谢谢参与, 问卷调查已提交', type: 'text', duration: 3500, closeOnClick: true });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.questionnaire-container {
  padding: 20px;
}
.questionnaire-title {
  font-size: 20px;
  line-height: 3;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}
.questionnaire-item {
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
  &__title {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
  }
  &__option {
    margin-bottom: 15px;
  }
}
.page-footer {
  padding: 10px;
  text-align: center;
  &__btn {
    width: 100%;
  }
}
</style>
